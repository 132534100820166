<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/paramSetting' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('pageQueryInfo')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button
                            @click="goIndex()"
                            type="primary"
                            size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="pageQueryInfo"
                  ref="pageQueryInfo"
                  label-width="150px"
                  class="pageQueryInfo"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12" style="display: none">
                      <el-form-item label="id" prop="id">
                        <el-input v-model="pageQueryInfo.id"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="站长名称" prop="isVip">
                        <el-input v-model="pageQueryInfo.adminName"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="站长登录账号" prop="memberName">
                        <el-input
                          v-model="pageQueryInfo.adminAccount"
                        ></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="站长身份证号" prop="memberCode">
                        <el-input
                          v-model="pageQueryInfo.adminCardId"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="站长密码" prop="deviceId">
                        <el-input
                          v-model="pageQueryInfo.adminPassword"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="站长电话" prop="deviceId">
                        <el-input v-model="pageQueryInfo.adminPhone"></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="电子围栏ID" prop="latitude">
                        <el-input
                          v-model="systemConfig.electronicFenceId"
                        ></el-input>
                      </el-form-item>
                    </el-col> -->

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="运营商ID" prop="">
                        <el-select
                          v-model="pageQueryInfo.supplierId"
                          placeholder="请选择运营商"
                          @change="selectCheck"
                          clearable
                        >
                          <el-option
                            v-for="item in operatorList"
                            :key="item.id"
                            :label="item.operatorName"
                            :value="item.id"
                          >
                            <!-- v-show="item.type == it.value||!selectIdsArr.includes(it.value)" -->
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      管辖电子围栏
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <!-- <el-button
                          type="primary"
                          @click="dialogTableVisible = true"
                          >新增</el-button
                        >

                        <el-dialog
                          title="电子围栏列表"
                          :visible.sync="dialogTableVisible"
                        >
                          <el-table :data="gridData">
                            <el-table-column
                              property="date"
                              label="省市区"
                              width="300"
                            ></el-table-column>
                            <el-table-column
                              property="name"
                              label="电子围栏名称"
                              width="300"
                            ></el-table-column>
                            <el-table-column
                              property="address"
                              label="所属运营商"
                            ></el-table-column>
                          </el-table>
                        </el-dialog> -->
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div>
                <el-table
                  :data="gridData"
                  border
                  fit
                  highlight-current-row
                  style="width: 100%"
                  @sort-change="sortChange"
                >
                  <el-table-column label="编号" type="index" width="50">
                  </el-table-column>
                  <el-table-column
                    label="省市区"
                    align="center"
                    prop="memberName"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.area }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="电子围栏名称"
                    align="center"
                    prop="memberName"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.electronicFenceName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="所属运营商"
                    align="center"
                    prop="orderPrice"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.operatorName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="所属管理员"
                    align="center"
                    prop="orderPrice"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.administratorName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label=""
                    align="center"
                    prop="id"
                    v-if="false"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.id }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="状态"
                    align="center"
                    prop="orderPrice"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.fenceStatus == 0 ? "未使用" : "已使用"
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="创建时间"
                    align="center"
                    prop="memberCode"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.createDt">{{
                        scope.row.createDt | dataFormat
                      }}</span>
                      <span v-else>---</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      srcList: [],
      operatorList: [],
      gridData: [],
      // 当前页数
      currPage: 1,
      pageSize: 10,
      page: 1,
      limit: 10,
      seeThis: 0,
      loading: true,
      id: "",
      dialogTableVisible: false,
      pageQueryInfo: {
        id: "",
        adminAccount: "",
        adminCode: "",
        supplierId: "",
        adminName: "",
        memberCode: "",
        adminCardId: "",
        adminPassword: "",
        adminPhone: ""
      }
    };
  },

  methods: {
    //图片预览点击遮罩层关闭
    handleClickItem() {
      // 获取遮罩层dom
      let domImageMask = document.querySelector(".el-image-viewer__mask");
      if (!domImageMask) {
        return;
      }
      domImageMask.addEventListener("click", () => {
        // 点击遮罩层时调用关闭按钮的 click 事件
        document.querySelector(".el-image-viewer__close").click();
      });
    },
    goIndex() {
      this.$router.go(-1);
    },
    //提交功能
    submitForm(formName) {
      var that = this;
      //   alert()
      if (that.pageQueryInfo.adminName == "") {
        that.$message("管理员名称不为空");
        return false;
      } else if (that.pageQueryInfo.adminAccount == "") {
        that.$message("管理员登录账号不为空");
        return false;
      } else if (that.pageQueryInfo.adminCardId == "") {
        that.$message("管理员身份证号不为空");
        return false;
      } else if (that.pageQueryInfo.adminPassword == "") {
        that.$message("管理员密码不为空");
        return false;
      } else if (that.pageQueryInfo.adminPhone == "") {
        that.$message("管理员电话不为空");
        return false;
      } else if (that.pageQueryInfo.supplierId == "") {
        that.$message("运营商ID不为空");
        return false;
      }
      that.$http
        .post("/userAdmin/save", { ...that.pageQueryInfo })
        .then(function(response) {
          if (response.data.code === 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true
            });
            that.$router.go(-1);
          } else {
            that.$notify.warning({
              title: "提示",
              message: response.data.message,
              showClose: true
            });
          }
        })
        .catch(function(error) {
          that.$notify.info({
            title: "提示",
            message: "保存失败",
            showClose: true
          });
        });
    },
    selectCheck() {},
    sortChange() {},
    loadEleFence() {
      var that = this;
      that.$http
        .post("/electronicFence/list", {
          currPage: that.currPage,
          pageSize: that.pageSize,
          condition: {
            //  adminCode:that.systemConfig.adminCode
          }
        })
        .then(function(response) {
          if (response.data.code == 200) {
            // console.log("---------");
            // console.log(response.data.data)
            that.gridData = response.data.data.data;
            // debugger
            console.log(response.data.data);
          }
        });
    },
    loadOperator() {
      var that = this;
      that.$http.post("/userOperator/queryAll", {}).then(function(response) {
        if (response.data.code == 200) {
          // console.log("---------");
          // console.log(response.data.data)
          that.operatorList = response.data.data;
          // debugger
          console.log(response.data.data);
        }
      });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/userAdmin/list", {
            page: 1,
            limit: 10,
            condition: {
              id: that.id
            }
          })
          .then(function(response) {
            console.log("---------");
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.pageQueryInfo = response.data.data.data[0];
              //  console.log("122"+response.data.data.data[0])
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      //   var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      //   if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
      //     this.seeThis = 0;
      //   }
      //   if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
      //     this.seeThis = 1;
      //   }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    }
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
    this.loadOperator();
    this.loadEleFence();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  }
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 114px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
